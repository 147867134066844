import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

const LazyApp = lazy(() => import('./App'))

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <LazyApp />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
